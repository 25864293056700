export const colors = {
    // primary: `#B16521`,
    primary: `#A98565`,
    secondary: `#573516`,

    violet: `#8a2c87`,
    blue: `#1c78b6`,
    pink: `#c54680`,
    grey: `#777`,
    brown: `#a2978c`,

    slide_color_menu_bar_left: `#8a2c87`,
    slide_color_menu_bar_right: `#3E3279`,
}

export const shared = {
    sEmailAddress:  `kontakt@pokojewstarejkarczmie.pl`,
    sPhoneNumber: `690 059 590`,
    sAddress: `Piekary 34, 32-060 Piekary`,
}
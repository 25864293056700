import React from "react"
// import PropTypes from "prop-types"
// import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

// s4n
// @see: https://github.com/gatsbyjs/gatsby/issues/1875
// import Link from "./Link"
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
// import MenuUtils from "./../s4n/Menu/utils"
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import loadable from '@loadable/component'
import Preloader from '../s4n/Preloader'
// import FormBasic from "./../forms/basic"
// import FormBasicMaterialUiExample1 from "./../forms/basic.material-ui.example-1"
// import FormBasicMaterialUiExample2 from "./../forms/basic.material-ui.example-2"
// import FormBasicMaterialUiExample3 from "./../forms/basic.material-ui.example-3"
// import UseDrupalBlockContentBasic from './../../hooks/drupal/blockContentBasic'
// import FormContactS4n from "./../forms/webform/contact_s4n"
// import FormBasicMaterialUi1 from "./../forms/basic.material-ui.test"
// import FormFormikJsonSchema from "./../forms/formik-json-schema"
// import Harmonogram from './../../components/s4n/Harmonogram/harmonogram'
// import Shop from './Shop/index'

// import FormikJsonSchema from "./../forms/formik-json-schema"
// import ReactJsonSchema from "./../forms/react-jsonschema-form"
// import CircleInteractive from "./../s4n/CircleInteractive"

// import { Accordion, Card } from 'react-bootstrap' // TEST for src/assets/sass/fix/bootstrap/_transitions.scss

import { kebabCase } from 'lodash';
// import Breadcrumbs from './Menu/Breadcrumbs'
// import PromotedFrontPage from './../s4n/Promoted/FrontPage'
// import FormContactS4n from './../forms/standalone/contact_s4n'
// import Booking from './../../../../components/forms/Booking'



const ComponentFunctional = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children, name, nodeId, productId } = props;

    const Booking = loadable(() => import('./../forms/Booking'), {fallback: <Preloader />})
    const FormContactS4n = loadable(() => import('./../forms/standalone/contact_s4n'), {fallback: <Preloader />})
    
    return (
        <React.Fragment>

            {/* {nodeId !== Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) && 
                <div className="container">
                    <Breadcrumbs nid={nodeId} />
                </div>
            } */}

            {nodeId !== Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) && 
                <Booking nid={nodeId} />
            }

            {children}

            {Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId && 
                <section id={`${kebabCase(t({ id: `soft4net_footer_contact_title` }))}`} className={`_section s4n-footer-contact`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="section-title w-100 text-center">{t({ id: `soft4net_footer_contact_title` })}</h2>
                            </div>
                            <div className="col-12 mx-auto d-flex justify-content-center">
                                <div>
                                    <p className="text-center" dangerouslySetInnerHTML={{__html: t({ id: `soft4net_footer_contact_form_title_alt` })}}></p>
                                    <FormContactS4n />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

        </React.Fragment>
    )
}

export default ComponentFunctional
/**
 * Menu component that queries for data
 * with Gatsby's StaticQuery component
 * 
 * @todo: refactor code to strategies or chainded functions!!! 
 *
 * @see: https://www.gatsbyjs.org/docs/static-query/
 * @see: https://reactjs.org/docs/code-splitting.html#suspense
 */

// @see: https://www.andrewl.net/article/gatsby-drupal-inline-images
// @see: https://www.npmjs.com/package/react-html-parser
// @see: https://gist.githubusercontent.com/andrewl/349410cbd5d9a26cf5286134fe5293df/raw/aecf4eae250e55980e98ec95c4937608f7706e03/article.js
// @todo: Replace relative URL with <Link />

import React from "react"
// import ReactDOMServer from 'react-dom/server';
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// s4n
import parse, { domToReact } from 'html-react-parser';
import useReplacement from './../useReplacement'

import _ from 'lodash'
import { useIntl, Link } from "gatsby-plugin-intl"
import styleToObject from 'style-to-object';
// import { renderToStaticMarkup } from 'react-dom/server';
import classNames from 'classnames';
import ImageUtils from './../../../../utils/gatsby/image'

//import Iframe from 'react-iframe';
//import SpeakerDeck from '../components/speakerdeck'
//import Gist from 'react-gist';
import useReactSlick, { ReactSlick } from './../Carousel/useReactSlick'






import Popup from './../../../../components/s4n/Popup'





export const getReplaceMethod = (intl, replacement) => {
    const t = intl.formatMessage;

    const htmlReactParserOptions = {
        replace: (htmlNode, index) => {
    
            /**
             * If i modify style here as global then it complains that:
             * First argument must be a string.
             * The one passed to cssToJs(style)
             * So i need to modify style right before destructing to react component.
             */
            // // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
            // if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
            //     // console.log(htmlNode.attribs.style)
            //     htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
            //     // console.log(htmlNode.attribs.style)
            // }
    
            /**
             * TEXT WE CAN RETURN FROM TRANSFORM RIGHT AWAY WE DON'T CARE ABOUT ATTRIBS
             * Replace [...] string with component
             * 
             * ReactDOMServer does not yet support Suspense.
             * Causes an error during gatsby build:
             * Error: Invariant Violation: Minified React error #295; visit https://reactjs.org/docs/error-decoder.html?invariant=295 for the full message or use the non-minified dev environment for full errors and additional helpful warnings.
             * @see: https://reactjs.org/docs/error-decoder.html/?invariant=294
             * @see: https://github.com/xing/hops/issues/714
             * @see: https://github.com/gatsbyjs/gatsby/issues/11960
             * @see: https://github.com/smooth-code/loadable-components
             */
            if (
                // This condition replaces whole DOM node <p>[s4n_team]</p> not just [s4n_team]!!!
                typeof htmlNode.children !== `undefined`
                && typeof htmlNode.children[0] !== `undefined`
                && `text` === htmlNode.children[0].type
            ) {
                let Component = null;
                const replacementTag = htmlNode.children[0].data;
    
                if (typeof replacement[replacementTag] !== `undefined`) {
                    // console.log(replacementTag);
                    Component = replacement[replacementTag];
                    // Component = loadable(() => import(replacement[htmlNode.data]));
                    // return <Component module={module} />
                }
    
                if (Component) {
                    return Component;
                    // return <Component />;
                    // return (
                    //     <Suspense fallback={<div>Loading...</div>}>
                    //         <Component />
                    //     </Suspense>
                    // )
                }
            }
    
            if ('tag' === htmlNode.type && 'a' === htmlNode.name) {
                // console.log(htmlNode)
    
                // Do not process link that opens in new tab!!! DO NOT CONVERT THEM TO <Link> !!!
                if (`_blank` === htmlNode.attribs['target']) {
                    return undefined;
                }
    
                // If it's special href not ordinary link skip processing
                // https://stackoverflow.com/questions/37896484/multiple-conditions-for-javascript-includes-method
                if ([`http:`, `https:`, `mailto:`, `tel:`].some(el => htmlNode.attribs['href'].includes(el))) {
                    return undefined;
                }
    
                // Set proper href for wrapping anchor if was set in backend - GALLERY
                if (htmlNode.attribs.href && htmlNode.attribs.href.startsWith('/sites/default/files/')) {
                    if ('tag' === htmlNode.children[0].type && 'img' === htmlNode.children[0].name) {
                        let uuid = htmlNode.children[0].attribs['data-entity-uuid'],
                        img = ImageUtils.getGatsbyImageDataByUuid(uuid);
                    
                        if (img) {
                            htmlNode.attribs.href = img.src;    
                        }
                    } else {
                        htmlNode.attribs.href = `${process.env.SOFT4NET_SOURCE_BASE_URL}${htmlNode.attribs.href}`;
                    }
                }
    
                // Gutenberg file download
                if (
                    false
                    && htmlNode.attribs && htmlNode.attribs.download 
                    // || htmlNode.attribs['class'].includes('wp-block-file__button')
                ) {
    
                    // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
                    if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
                        htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
                    }
    
                    return <a {...htmlNode.attribs} >
                        { t({ id: "soft4net_node_harmonogram_coach" }) }
                    </a>
                }
    
    
    
                // @todo: This should just convert to <Link> tag not do replacing!!!
                if (`text` === htmlNode.children[0].type) {
    
                    let linkTo = htmlNode.attribs['href'];
    
                    // Parse anchor HREFs START
                    const checkHref = [
                        '/' + intl.locale, // '/en/page-url' => '/page-url'
                        process.env.SOFT4NET_SOURCE_BASE_URL + '/' + intl.locale, // 'https://www.domain.tld/en/page-url' => '/page-url' | removes prefix added in administration!
                    ];
    
                    checkHref.forEach(hrefStartsWith => {
                        if (htmlNode.attribs.href && htmlNode.attribs.href.startsWith(hrefStartsWith)) {
                            linkTo = htmlNode.attribs['href'].replace(hrefStartsWith, '');
                        }
                    });
    
                    // change only link that has text type inside not other elements
                    if (linkTo) {
                        delete htmlNode.attribs['href'];
    
                        // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
                        if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
                            htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
                        }
    
                        return <Link to={linkTo} {...htmlNode.attribs} >
                            { htmlNode.children[0].data }
                        </Link>
                    }
                    // Parse anchor HREFs STOP
                }
    
            }
    
            if ('tag' === htmlNode.type && 'img' === htmlNode.name) {
    
                /**
                 * In Drupal after uploading file via WYSWIG editor:
                 * <img 
                 *  alt="Tekst alternatywny" 
                 *  data-entity-type="file" 
                 *  data-entity-uuid="ebd86225-bd84-4d7e-8ffd-074a011e0a90" 
                 *  src="/sites/default/files/inline-images/Screenshot%202019-05-16%20at%2013.37.58.png" />
                 */
    
                // @todo: Replace relative URL with <Link />
                
                let uuid = htmlNode.attribs['data-entity-uuid'],
                    img = ImageUtils.getGatsbyImageDataByUuid(uuid);
                
                if (img) {
    
                    // // Set proper href for wrapping anchor if was set in backend
                    // let htmlNodeParent = htmlNode.parent;
                    // if ('tag' === htmlNodeParent.type && 'a' === htmlNodeParent.name) {
                    //     if (htmlNodeParent.attribs.href && htmlNodeParent.attribs.href.startsWith('/sites/default/files/')) {
                    //         console.log(htmlNodeParent.attribs.href)
                    //         console.log(htmlNodeParent.attribs.href.startsWith('/sites/default/files/'))
                    //         htmlNodeParent.attribs.href = `${process.env.SOFT4NET_SOURCE_BASE_URL}${htmlNodeParent.attribs.href}` // THIS DO NOT WORK, WHY???!!!
                    //         console.log(htmlNodeParent.attribs.href)
                    //     }
                    // }
    
                    if (
                        _.isEmpty(htmlNode.attribs['alt'])
                        // `` === htmlNode.attribs['alt']
                        // || typeof htmlNode.attribs['alt'] === `undefined` 
                    ) {
                        let oDrupalMediaImageData = ImageUtils.getDrupalMediaImageDataByUuid(uuid, intl.locale);
                        htmlNode.attribs['alt'] = oDrupalMediaImageData ? oDrupalMediaImageData.alt : ``;
    
                        // if (img.alt) {
                        //     htmlNode.attribs['alt'] = img.alt;
                        // }
                    }
    
                    let useGatsbyImage = true;
                    let htmlNodeParent = htmlNode;
                    while (htmlNodeParent = htmlNodeParent.parent) {
                        // For all OwlCarousel images disable Gatsby image, except hero and standard slider!
                        if (
                            'tag' === htmlNodeParent.type
                            && 'figure' === htmlNodeParent.name
                            && htmlNodeParent.attribs['class']
                            && htmlNodeParent.attribs['class'].includes('owl-carousel')
                            // && !htmlNodeParent.attribs['class'].includes('hero')
                        ) {
                            // console.log(htmlNodeParent)
                            useGatsbyImage = false;
                            break;
                        }
                    }
    
                    // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
                    if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
                        htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
                    }
    
                    if (useGatsbyImage) {
                        return <Img 
                            fluid={img}
                            {...htmlNode.attribs}
                            // loading="eager"
                            // objectFit="cover"
                        />
                    } else {
                        // console.log(htmlNode)
                        // return <img src={img.src} alt={htmlNode.attribs['alt']} />
    
                        // console.log(htmlNode.attribs);
    
                        // class="owl-lazy" data-src="https://placehold.it/350x450&text=1"
    
                        // const htmlNodeAttribs = {
                        //     ...htmlNode.attribs
                        // }
    
                        // @see: https://owlcarousel2.github.io/OwlCarousel2/demos/lazyLoad.html
                        delete htmlNode.attribs['src'];
                        // htmlNode.attribs['src'] = `https://www.eaglevillemotors.com/1/images/loading-gear.gif`;
                        htmlNode.attribs['data-src'] = img.src;
                        htmlNode.attribs['class'] = classNames({
                            [`${htmlNode.attribs['class']}`]: true,
                            [`owl-lazy`]: true,
                        });
    
                        if (htmlNodeParent.attribs['class'].includes('hero')) {
                            return <div {...htmlNode.attribs}></div>
                        }
                        
                        return <img {...htmlNode.attribs} />
                    }
                }
    /*
                else {
                    return undefined;
    
                    // we need to change property class to className for React! ???
                    htmlNode.attribs['className'] = htmlNode.attribs['class'];
                    delete htmlNode.attribs['class'];
    
                    htmlNode.attribs['src'] = `${process.env.SOFT4NET_SOURCE_BASE_URL}${htmlNode.attribs['src']}`
                    return <img {...htmlNode.attribs}/>
                }
    */
    
                //return null;
    
                // not performant refactor not to be inside transform, this rusn for each HTML img node!
    
                // for (let i = 0; i < data.allFileFile.edges.length; i++) {
                //     if (data.allFileFile.edges[i].node.drupal_id === uuid && data.allFileFile.edges[i].node.localFile) {
                //         return <Img fluid={data.allFileFile.edges[i].node.localFile.childImageSharp.fluid}/>
                //     }
                // }
    
                // data.allFileFile.edges.map(({ node }) => {
                //     if (node.drupal_id === uuid && node.localFile) {
                //         return <Img fluid={node.localFile.childImageSharp.fluid}/>
                //     }
                // })
    
            }
    
            // Gutenberg widget "Cover"
            if ('tag' === htmlNode.type && 'div' === htmlNode.name && htmlNode.attribs && htmlNode.attribs.class && htmlNode.attribs.class.includes('wp-block-cover')) {
                let uuid = htmlNode.attribs['data-entity-uuid'],
                    img = ImageUtils.getGatsbyImageDataByUuid(uuid);
                
                if (img) {
                    
                    // !!! ALWAYS BEFORE DECONSTRUCTING PROPS CONVERT STRING STYLE TO OBJECT !!!
                    if (htmlNode.attribs && htmlNode.attribs.style && typeof htmlNode.attribs.style === 'string') {
                        htmlNode.attribs.style = styleToObject(htmlNode.attribs.style);
                        htmlNode.attribs.style = {
                            ...htmlNode.attribs.style,
                            'background-image': `url('${img.src}')`,
                        }
    
                        // console.log(htmlNode.attribs.style)
    
                        return (
                            <div {...htmlNode.attribs} >
                                {domToReact(htmlNode.children, htmlReactParserOptions)}
                            </div>
                        );
    
                        // @see: https://stackoverflow.com/questions/45205593/how-to-convert-a-json-style-object-to-a-css-string
                        // const styleString = (
                        //     Object.entries(htmlNode.attribs.style).reduce((styleString, [propName, propValue]) => {
                        //         return `${styleString}${propName}:${propValue};`;
                        //     }, '')
                        // );
                        // htmlNode.attribs.style = styleString;
                        // return domToReact(htmlNode, htmlReactParserOptions); // Alter the object here to suit your needs
                    }
                }
            }
    
            // Change owl-carouse from ul/li to div
            if ('tag' === htmlNode.type && 'ul' === htmlNode.name && htmlNode.attribs && htmlNode.attribs.class && htmlNode.attribs.class.includes('owl-carousel')) {  
                return (
                    <div {...htmlNode.attribs}>
                        {htmlNode.children.map((node, index) => {
                            node.attribs['class'] = classNames({
                                [`item`]: true,
                                [`${node.attribs['class']}`]: true,
                            });
    
                            return (
                                <div key={`owl-carousel-item-${index}`} {...node.attribs}>
                                    {domToReact(node.children, htmlReactParserOptions)}
                                </div>
                            )
                        })}
                    </div>
                );
            }
    
            if (
                `tag` === htmlNode.type 
                && `figure` === htmlNode.name
                && htmlNode.attribs[`class`]
                && htmlNode.attribs[`class`].includes(`wp-block-gallery`)
                && htmlNode.attribs[`class`].includes(`enable-carousel`)
            ) {
                // return useReactSlick(htmlNode);
                // return undefined;
                return <ReactSlick htmlNode={htmlNode} />
            }
    
            // Soft4Net Popup
            if (
                `tag` === htmlNode.type 
                // && `div` === htmlNode.name // i disable this condition in case Gutenberg change somethign in the future
                && htmlNode.attribs[`class`]
                && htmlNode.attribs[`class`].includes(`s4n-popup`)
            ) {
                return <Popup>
                    {domToReact(htmlNode.children, htmlReactParserOptions)}
                </Popup>
            }




            // if ('transformedNodeAttribsStyle' === isTransformed) {
            //     return domToReact(htmlNode, htmlReactParserOptions); // Alter the object here to suit your needs
            // }
    
    
    
            // else if (htmlNode.type === 'tag' && htmlNode.name === 'iframe') {
            //     let url = htmlNode.attribs.src;
        
            //     if (url.search('http') === -1) {
            //         if (url.search('//') === -1) {
            //             url = "//" + url;
            //         }
                    
            //         url = "https:" + url;
            //     }
        
            //     let id = url.split("/").pop();
                
            //     return <Iframe
            //         url={url}
            //         width={htmlNode.attribs.width}
            //         height={htmlNode.attribs.height}
            //         id={id}
            //         display="initial"
            //         position="relative"
            //         allowFullScreen
            //     />;
            // }
            // else if (htmlNode.type === 'script') {
            //     if ('src' in htmlNode.attribs && 'data-id' in htmlNode.attribs) {
            //         if (htmlNode.attribs['src'].search('speakerdeck') > -1) {
            //             return <SpeakerDeck data-id={htmlNode.attribs['data-id']} />;
            //         }
            //     } else if ('src' in htmlNode.attribs) {
            //         if (htmlNode.attribs['src'].search('gist.github') > -1) {
            //             var id = htmlNode.attribs['src'].split('/').pop().split('.')[0];
            //             return <Gist id={id} />;
            //         }
            //     }
    
            // }
    
            // If the function does not return anything, or returns undefined, then the default behaviour will occur and the parser will continue as usual.
            // return undefined;
        },
    }

    return htmlReactParserOptions;
}

const useProcessContentTags = (html, oReplacement) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const replacement = useReplacement(oReplacement);

    return parse(html, getReplaceMethod(intl, replacement));
}

export default useProcessContentTags
/**
 * Menu component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
// import PropTypes from "prop-types"

// plugins added by s4n
import { useIntl, Link } from "gatsby-plugin-intl"
import LanguageSwitcher from '../../languageSwitcher'
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
import MenuUtils from "./utils"
import classNames from 'classnames';
import kebabCase from "lodash.kebabcase"

// @see: https://www.drupal.org/project/jsonapi_extras/issues/2942851



const MenuLevelGenerator = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { data, level, ariaLabelledBy } = props;

    // const pathAndHash = globalHistory.location.pathname + globalHistory.location.hash;
    // const path = globalHistory.location.pathname;

    const enableLanguage = props.enableLanguage || false;
    const injectContentToMenuId = props.injectContentToMenuId || {};
    const disableChildren = props.disableChildren || false;
    const disableDropdown = disableChildren || (props.disableDropdown || false); // IS THAT CONSTRUCTION OK ??
    const generateUriSameAsTitle = props.generateUriSameAsTitle || false;
    const navLinkAddClass = props.navLinkAddClass || false;
    const disableActiveStatus = props.disableActiveStatus || false;

    // recursive menu tree generator
    // @see: https://stackoverflow.com/questions/45286713/render-recursively-a-nested-data-in-react
    // @see: https://www.drupal.org/project/jsonapi_extras/issues/2942851

    // const menuUtils = new MenuUtils(); we use static methods!

    const currentMenuItemFromRoute = MenuUtils.getCurrentMenuItemFromRoute();

    const classNameUlClass = classNames({
        "navbar-nav": level === 0,
        [`level-${level}`]: level === 0,
        [props.className]: level === 0 && props.className,
        [`menu-type-${props.type}`]: level === 0 && !props.className,
        "dropdown-menu": !(level === 0) && !disableDropdown,
    });

    let propsUl = null;

    if (ariaLabelledBy) {
        propsUl = {
            "aria-labelledby": ariaLabelledBy,
        }
    }

    const firstUlLiMobileGoToParentPage = props.firstUlLiMobileGoToParentPage || null;

    return (
        <ul className={classNameUlClass} {...propsUl}>

            {firstUlLiMobileGoToParentPage}

            {data.map(({ node }) => {

                // console.log(node)
                
                let nodeLinkUrl = generateUriSameAsTitle ? `/#${kebabCase(node.title)}` : node.link.url;
                let nodeLinkUri = kebabCase(node.link.uri);
                let isActive = !disableActiveStatus && currentMenuItemFromRoute && currentMenuItemFromRoute.drupal_id === node.drupal_id ? true : false;
                let classNameLi = [

                    node.children.length && 0 < level ? 'dropdown-submenu' : null,

                    ariaLabelledBy ? 'dropdown-item' : 'nav-item',
                    0 === level ? !disableDropdown ? 
                        (node.children.length || typeof injectContentToMenuId[nodeLinkUri] !== `undefined` ? 
                            'dropdown' 
                            : (0 === level ? 
                                null 
                                : 'dropdown-item')) 
                        : null
                    : null,
                    isActive ? 'active' : null,
                    // @see: https://stackoverflow.com/questions/16576983/replace-multiple-characters-in-one-replace-call
                    nodeLinkUri,
                ],
                propsLi = {
                    
                },
                classNameA = classNames({
                    "nav-link": true,
                    'dropdown-toggle': node.children.length || typeof injectContentToMenuId[nodeLinkUri] !== `undefined`,
                    [`${navLinkAddClass}`]: navLinkAddClass,
                }),
                propsA = null;

                if (node.children.length || typeof injectContentToMenuId[nodeLinkUri] !== `undefined`) {
                    propsA = {
                        "id": nodeLinkUri,
                        // "href": "#",
                        "role": "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                    };
                }

                // For mobile since we cannot tap on dropdown-toggle, or pass it as children to MenuLevelGenerator -> firstUlLiMobileGoToParentPage
                const firstUlLiMobileGoToParentPage = (
                    <li className={`dropdown-item go-to-parent-item`}>
                        <i><Link to={nodeLinkUrl} className="nav-link">{t({ id: `soft4net_go_to_parent_item` })}{node.title}</Link></i>
                    </li>
                )

                return (
                    <li key={node.id} className={classNameLi.join(` `)}>
                        {/* {!!node.children.length ? 
                            // Bootstrap 4 requires href="#" for parent dropdown wrapper
                            <a 
                                className={classNameA}
                                {...propsA}
                            >
                                {node.title}
                            </a> : 
                            <Link 
                                to={nodeLinkUrl} 
                                className={classNameA}
                                {...propsA}
                            >
                                {node.title}
                            </Link>
                        } */}
                        <Link 
                            to={nodeLinkUrl} 
                            className={classNameA}
                            {...propsA}
                        >
                            {node.title}
                        </Link>

                        {!disableChildren && !!node.children.length && 
                            <MenuLevelGenerator 
                                firstUlLiMobileGoToParentPage={firstUlLiMobileGoToParentPage}
                                {...props} 
                                key={`key-nav-menu-type-${props.type}-level-${level}-node-id-${node.id}`} 
                                data={node.children} 
                                level={level + 1} 
                                ariaLabelledBy={nodeLinkUri}
                            />
                        }

                        {/* Inject custom content to menu */}
                        {typeof injectContentToMenuId[nodeLinkUri] !== `undefined` && <ul className="dropdown-menu" aria-labelledby={nodeLinkUri}>
                            {injectContentToMenuId[nodeLinkUri]}
                        </ul>}
                    </li>
                );
            })}

            {/* { console.log(currentMenuItemTranslations) } */}

            {/* Render languages only once for main nesting level */}
            {enableLanguage && 0 === level && <LanguageSwitcher currentMenuItemTranslations={MenuUtils.getCurrentMenuItemTranslations()} />}
        </ul>
    );
}

export default MenuLevelGenerator
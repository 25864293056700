/**
 * Menu component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// plugins added by s4n
import { injectIntl } from 'gatsby-plugin-intl'
import kebabCase from "lodash.kebabcase"
import useProcessContentTags from './parseContentProcessor/useProcessContentTags'


const UseDrupalBlockContentBasic = (props) => {
    const { blockId, intl } = props;
    const t = intl.formatMessage;

    const data = useStaticQuery(graphql`
        query {
            allBlockContentBasic {
                edges {
                    node {
                        id
                        drupal_id
                        drupal_internal__id
                        internal {
                            type
                        }
                        
                        langcode
                        status
                        info
                        body {
                            value
                        }
                    }
                }
            }
        }
    `)

    //console.log(injectIntl)

    // prepare data collection
    let dataCollectionTranslated = {};
    data.allBlockContentBasic.edges.forEach(({ node }) => {
        if (node.status && intl.locale === node.langcode) {
            dataCollectionTranslated[node.drupal_internal__id] = node;
        }
    })

    const drupalBlockContentBasic = dataCollectionTranslated[blockId];
    const htmlReactParserParsedHtml = useProcessContentTags(drupalBlockContentBasic.body.value); // We replace <a href=""><a> with <Link to={}>...

    // return drupalBlockContentBasic
    return (
        <React.Fragment>
            {drupalBlockContentBasic ? 
                <div 
                    className={kebabCase(drupalBlockContentBasic.info)} 
                    // dangerouslySetInnerHTML={{ __html: drupalBlockContentBasic.body.value }}
                >{htmlReactParserParsedHtml}</div> : null}
        </React.Fragment>
    )
}

export default injectIntl(UseDrupalBlockContentBasic)
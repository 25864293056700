import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { useIntl } from "gatsby-plugin-intl"
import { togglePopupStatus } from './../../../s4n/state/actions/popup'
// import Helmet from "react-helmet"
// import $ from 'jquery'
// import useVersion from './../../../../hooks/useVersion'

import { domToReact } from 'html-react-parser';


const Component = (props) => {
    
    const intl = useIntl();
    const t = intl.formatMessage;

    // const currentBuildVersion = useVersion();
    const { children } = props;

    // console.log(htmlNode)

    const textClosePopup = intl.locale === `en` ? `Close` : `Zamknij`; // t({ id: `soft4net_popup_close` })

    return (
        <React.Fragment>
            {props.isPopupOpened &&             
                <div id={`popupContainer`}>
                    <div className="popup js-popup">
                        <div onClick={() => props.togglePopupStatus(props.isPopupOpened)} className="popup__overlay js-popup-close js-overlay"></div>
                        <div className="popup__box js-popup-box">                        
                            <button onClick={() => props.togglePopupStatus(props.isPopupOpened)} title={textClosePopup} className="popup__close popup__close--popup js-popup-close">
                                {/* <span className="icon icon-close"></span> */}
                                <i className="fas fa-times"></i>
                            </button>
                            {/* <a href="https://www.nosalowypark.pl/pakiety/szczegoly-pakietu?OfferID=221360" className="popup__img-hld">
                                <img className="popup__img" src="https://u.profitroom.pl/2019-nnosalowypark-pl/uploads/PopupBodyandMindzlotypo17.08.jpg" />
                            </a> */}
                            {children}
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isPopupOpened: state.popup.isPopupOpened,
})

// const mapDispatchToProps = (dispatch, ownProps) => ({
//     togglePopupStatus: () => dispatch(togglePopupStatus(!ownProps.isPopupOpened)),
// })
const mapDispatchToProps = dispatch => ({
    togglePopupStatus: (isPopupOpened) => dispatch(togglePopupStatus(!isPopupOpened)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);


import { useStaticQuery, graphql } from 'gatsby'

const useAllFileFile = () => {
    const data = useStaticQuery(graphql`
        query {
            allFileFile {
                edges {
                    node {
                        drupal_id
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1920) {
                                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.

                                    # ...GatsbyImageSharpFluid
                                    # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                                    # ...GatsbyImageSharpFluid_withWebp
                                    ...GatsbyImageSharpFluid_withWebp_noBase64

                                    # If you don’t want to use the blur-up effect, choose the fragment with noBase64 at the end.
                                    # @see: https://www.gatsbyjs.org/packages/gatsby-image/#fragments
                                    # ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return data
}

export default useAllFileFile
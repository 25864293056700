// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from "react"

// s4n
// import GeneralUtils from './../utils/common'
import { useIntl } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import Menu from "./s4n/Menu/menu"
// import MenuUtils from "./../components/s4n/Menu/utils"
import LanguageSwitcher from './../components/languageSwitcher'
// import Search from './../components/forms/Search';
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"

import Logo from './../images/custom/logo.svg'
import LogoWhite from './../images/custom/logo-white.svg'
// import Logo from './../images/custom/logo-white-48.png'

// import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

// import withDrupalOauthConsumer from '../components/drupal-oauth/withDrupalOauthConsumer';
// import SignIn from './s4n/AccountManagement/SignIn/SignIn';
// import LogoutLink from './s4n/AccountManagement/LogoutLink/LogoutLink';

import Accessibility from './s4n/Accessibility'
// import PromotedFrontPage from './s4n/Promoted/FrontPage'

import { shared as ConfigShared } from './s4n/Config/Shared'
import classNames from 'classnames';


import { connect } from 'react-redux'
import { toggleModalBooking } from './../s4n/state/actions/modal'
import { bindOnClickOnKeyDown } from './s4n/Accessibility'

// import $ from "jquery"
// import useScrollHandler from './../hooks/useScrollHandler'

// import Sticky from 'react-stickynode';


// ES6
//import Webform from 'hn-react-webform';



// import ContextScrollableNodes from './../s4n/context/ScrollableNodes';



// const Header = (props) => {
const Header = React.forwardRef((props, ref) => {
  
    const intl = useIntl();
    const t = intl.formatMessage;

    const { siteTitle, nodeId } = props;

    // const contextScrollableNodes = useContext(ContextScrollableNodes);

    // Shop START
    const {
      dispatch,
      // cart: { carts, itemCount },
      // cartFlyout: { open }
    } = props;
    // Shop STOP
// console.log(props.className)
    // const injectContentToMenuId = {}

    const aAvailableLanguages = process.env.SOFT4NET_AVAILABLE_LANGUAGES.split(','); // [ 'pl', 'en' ]

// STICKY HEADER PROCESSOR START
    // @see: https://stackoverflow.com/questions/44612364/toggle-class-based-on-scroll-react-js
    // @see: https://stackoverflow.com/questions/35153599/reactjs-get-height-of-an-element

    // const headerRef = useRef(null);
    // const scroll = useScrollHandler(headerRef);
    // const HEADER_STICKY_MENU_CLASS = `stuck`;
    // const HEADER_STICKY_MENU_CLASS = `fixed-top`;
    // const HEADER_STICKY_MENU_CLASS = `alt`;
    // const [scroll, setScroll] = useState(false);



    // const getMenuHeight = () => {       
    //   // const headerHeight = $('header.header').outerHeight();
    //   return headerRef.current ? headerRef.current.clientHeight : 0;
    // }

    // @see: https://github.com/idiotWu/smooth-scrollbar/blob/1a9227951d62a2e508e71056fc94750e755fd3b3/docs/api.md#scrollbarscrollintoview
    // @see: https://github.com/idiotWu/smooth-scrollbar/issues/97
    // const scrollTo = (anchorHash, event = null) => {
    //   const { scrollbar } = scrollbarRef.current;
    //   // const $target = $(anchorHash);
    //   const target = document.getElementById(anchorHash.split(`#`)[1]);

    //   // if ($target.length) {
    //   if (target) {
    //     event && event.preventDefault();

    //     // Scroll behind standard header to enable alternative header with different height!
    //     const menuHeight = getMenuHeight();
    //     if (scrollbar.offset.y < getMenuHeight()) {
    //     // if (scrollbar.offset.y < menuHeight) {
    //       // scrollbar.scrollTop = getMenuHeight() + 1; // Scroll 1px behind
    //       scrollbar.scrollTop = getMenuHeight() * 2; // Scroll 2 times behind standard header to be sure it will have different height for alternative mode!
    //       // scrollbar.scrollTop = menuHeight * 2; // Scroll 1px behind
    //     }

    //     // const offsetY = $target.offset().top - getMenuHeight();
    //     // const offsetY = target.offsetTop;

    //     // scrollbar.scrollTo(0, offsetY, 500);
    //     // scrollbar.scrollTop = offsetY; // Gets or sets scrollbar.offset.y
    //     // scrollbar.setPosition(0, offsetY); // Likes the window.scrollTo() method, you can use this method to set the scrollbar to the given offset without easing
    //     // @see: https://github.com/idiotWu/smooth-scrollbar/blob/develop/docs/api.md#scrollbarscrollintoview
    //     scrollbar.scrollIntoView(
    //       // $target[0], 
    //       target,
    //       {
    //       offsetTop: getMenuHeight() // we must calculate dynamically!
    //     });

    //     // setTimeout(() => {
    //     //   const $li = $(this).parent();
    //     //   $li.siblings().removeClass(`active`);
    //     //   $li.addClass(`active`);
    //     // }, 1000);

    //     return true;
    //   }

    //   return false;
    // }

    // const goToClickedAnchorId = function(event) {
    //     const anchorHash = $(this).prop('hash');
    //     // console.log(anchorHash)
    //     // anchorHash === #section-id
    //     if (anchorHash == '') {
    //       return true;
    //     }

    //     scrollTo(anchorHash, event);

    //     return true;
    // }



    // useLayoutEffect(() => {
    //   if (headerRef.current) {
    //       const height = headerRef.current.clientHeight
    //       // dispatch({type:'GRID_WIDGET_HEIGHT', widget:name, height})
    //   }
    // });

    // const isHeaderAlt = headerRef.current ? headerRef.current.classList.contains(HEADER_STICKY_MENU_CLASS) : false;

    const oLinkLogoClassNames = classNames({
      'navbar-brand': true,
      // 'goto-scroll': Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId,
  });
// STICKY HEADER PROCESSOR STOP

    const handleOpenBookingModal = (props) => {
      props.toggleModalBooking(props.isModalBookingOpened); // this modifies redux store state
    }

    return (
      <React.Fragment>
        {/* <Sticky enabled={true} top={50} bottomBoundary={1200}> */}
        {/* <div style={{minHeight: getMenuHeight()}}> */}
          <header 
            id={`header-main`}
            
            // ref={headerRef}
            ref={ref}

            className={props.className}
          >
            <nav id={`menu-main`} className="navbar navbar-expand-lg">
              {/* {!isHeaderAlt ? <LanguageSwitcher /> : null} */}

              <Link 
                to={Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId ? `/#top` : `/`} 
                title={siteTitle} 
                className={oLinkLogoClassNames}
              >
                <img src={Logo} alt={siteTitle} className="logo" />
                <img src={LogoWhite} alt={siteTitle} className="logo alt" />
              </Link>

              <a 
                  className="btn action"
                  {...bindOnClickOnKeyDown(() => handleOpenBookingModal(props))}
              ><i className="fas fa-calendar-day"></i>{intl.locale === `en` ? `Book now` : `Zarezerwuj teraz`}</a>

              <button data-open={`Menu`} tabIndex="0" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label={t({ id: `soft4net_toggle_navigation` })}>
                {/* Bootstrap icons */}
                {/* <span className="navbar-toggler-icon"></span> */}
                {/* Font awesome icon */}
                {/* <i className="fas fa-bars"></i> */}
                {/* @see: https://icons8.com/icon/82853/menu */}
                {/* <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 24 24" style={{fill: `#fff`}}>
                  <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="42" height="35" viewBox="0 0 24 32" style={{fill: `currentColor`}}>
                  <path d="M 0 2 L 0 4 L 24 4 L 24 2 Z M 0 11 L 0 13 L 24 13 L 24 11 Z M 0 20 L 0 22 L 24 22 L 24 20 Z"></path>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="42" height="35" viewBox="0 0 24 32" style={{fill: `currentColor`}}>
                  <path d="M 5.7070312 4.2929688 L 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 L 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 L 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 L 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 z"></path>
                </svg>

              </button>
              <div className="collapse navbar-collapse" id="navigation">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <Menu 
                          type="main" 
                          disableActiveStatus={true} 
                          navLinkAddClass={``} 
                          generateUriSameAsTitle={true} 
                          disableChildren={true} 
                          // _injectContentToMenuId={injectContentToMenuId} 
                        />
                        {/* <a href={`tel:+48${ConfigShared.sPhoneNumber}`}><i className="fas fa-phone-square-alt"></i> {ConfigShared.sPhoneNumber}</a> */}
                        {/* <a data-encoded={GeneralUtils.rot13(ConfigShared.sEmailAddress.replace(/ /g, ``))} data-prefix={`<i className="fa fa-envelope" aria-hidden="true"></i> `} href="mailto:"></a> */}
	                      <div className="social">
                          <a className="facebook" href="https://www.facebook.com/pokojewstarejkarczmie/" rel="noreferrer" target="_blank" title="Facebook"><i className="fab fa-facebook">‌‌</i></a>
                          <a className="instagram" href="https://www.instagram.com/pokoje_w_starejkarczmie/" rel="noreferrer" target="_blank" title="Instagram"><i className="fab fa-instagram">‌‌</i></a>
                        </div>
                        {aAvailableLanguages.length > 1 &&
                          <LanguageSwitcher />
                        }
                        <div className="accessibility _vertical d-none d-lg-flex">
                          <Accessibility />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

            </nav>
          </header>
        {/* </div> */}
        {/* </Sticky> */}
      </React.Fragment>
    )

// }
});

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => ({isModalBookingOpened: state.modal.isModalBookingOpened})
const mapDispatchToProps = dispatch => ({
  toggleModalBooking: isModalBookingOpened => dispatch(toggleModalBooking(!isModalBookingOpened)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  // withDrupalOauthConsumer(
    Header
    // React.memo(Header)
  // )
);
/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"

// import "./layout.css"
// import cssModule from "./../assets/sass/components/Layout.module.scss"
// import "./../../node_modules/bootstrap/scss/bootstrap.scss";
import "./../assets/sass/index.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.css"
// import "./../assets/sass/HighContrast.module.css"

// s4n
// import $ from "jquery"
import { useIntl, navigate } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
// import Preloader from './s4n/Preloader'
import kebabCase from "lodash.kebabcase"
import classNames from 'classnames';
import S4nLayoutBlock from './s4n/layoutBlock'
// import * as loadScript from 'simple-load-script'
// import loadScript from 'simple-load-script';
// @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
import { withPrefix } from 'gatsby'
// import YupSetLocale from './../components/forms/yup-setlocale';

import drupalOauth from './drupal-oauth/drupalOauth';
import withDrupalOauthProvider from './drupal-oauth/withDrupalOauthProvider'
import { connect } from 'react-redux'

// s4n
import useVersion from './../hooks/useVersion'
import useScreenSize from './../hooks/useScreenSize'
// import ScrollReveal from 'scrollreveal'



// import ContextScrollableNodesWrapper from './../s4n/context/ScrollableNodes/wrapper-class'



// s4n implementation
// import SmoothScroll from './s4n/SmoothScroll';

// @see: https://github.com/idiotWu/react-smooth-scrollbar
// import SmoothScrollbar from 'smooth-scrollbar';
// import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
// import Scrollbar from 'react-smooth-scrollbar';

// import useScrollHandler from './../hooks/useScrollHandler/index'

// SmoothScrollbar.use(OverscrollPlugin);



const Layout = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children } = props;

    const currentBuildVersion = useVersion();

    // const [context, setContext] = useState({
    //   // loadedScripts: false,
    //   isHeroSlider: false,
    //   windowLoaded: false,
    // });   

    const [htmlElement, setHtmlElement] = useState(null);
    const [bookingComWidgetTargetElemId, setBookingComWidgetTargetElemId] = useState(null);

    // const scrollbarRef = useRef(null);

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
      // const isBrowser = () => typeof window !== 'undefined'
      // if (!isBrowser()) {
      //   return false
      // }

      setHtmlElement(document.getElementsByTagName(`html`)[0]);

      // Embeed booking.com widget START
      var randomId = Math.floor(Math.random() * 100000);
      var targetElemId = 'bcom_rwidget_' + randomId;
      setBookingComWidgetTargetElemId(targetElemId);
      var script   = document.createElement('script');
      script.type  = 'text/javascript';
      script.async = true;
      script.src   = 'https://www.booking.com/review_widget/pl/pokoje-w-starej-karczmie.pl.html?tmpl=review_widget/review_widget&wid=' + targetElemId + '&wtype=box_small&hotel_id=6257735&widget_language=pl';
      var nodeTmp     = document.getElementsByTagName('script')[0];
      nodeTmp.parentNode.insertBefore(script, node);
      // Embeed booking.com widget STOP


      
      const { node } = props;
      let nodeId = null;
      let productId = null;

      if (node) {
        nodeId = node.drupal_internal__nid || null;
        productId = node.drupal_internal__product_id || null;
      }

    }, []);

    const headerRef = useRef(null);
    // const headerRef = React.createRef();
    // const scroll = useScrollHandler(headerRef);
    const HEADER_STICKY_MENU_CLASS_SCROLLED = `alt`;
    const oHeaderClassNames = classNames({
      'header navigation fixed-top': true,
      // [HEADER_STICKY_MENU_CLASS_SCROLLED]: !scroll, // for now we add class via header.classList.add(HEADER_STICKY_MENU_CLASS_SCROLLED)
      [HEADER_STICKY_MENU_CLASS_SCROLLED]: true,
    });

    // @see: https://www.gatsbyjs.org/docs/use-static-query/
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)



    const { node } = props;
    const className = props.className || false;

    let nodeId = null;
    let productId = null;
    let oWrapperMainClassNode = null;

    if (node) {
      nodeId = node.drupal_internal__nid || null;
      productId = node.drupal_internal__product_id || null;

      oWrapperMainClassNode = {
        [node.internal ? node.internal.type : null]: true,
        [`drupal_internal__nid-${node.drupal_internal__nid}`]: node.drupal_internal__nid,
        [`drupal_internal__product_id-${node.drupal_internal__product_id}`]: node.drupal_internal__product_id,
        [kebabCase(node.title)]: true,
      }
    }



    const oClassNamesHtml = classNames({
      [`accessibility-high-contrast`]: props.isHighContrastMode,
      [`accessibility-font-size-${props.fontSize}`]: true,
      // hasHeroSlider: context.isHeroSlider,
    });

    // const $html = $(`html`);
    const html = htmlElement;
    if (html) {
      if (props.isHighContrastMode) {
        html.classList.add(`accessibility-high-contrast`);
      } else {
        html.classList.remove(`accessibility-high-contrast`);
      }
      // html.classList.toggle(`accessibility-high-contrast`);

      if (props.fontSize) {
        html.classList.forEach(className => {
          if (className.startsWith(`accessibility-font-size-`)) {
            html.classList.remove(className);
          }
        });
        html.classList.add(`accessibility-font-size-${props.fontSize}`);
      }
    }

    

    const oClassNamesBody = classNames({
      [`${useScreenSize()}`]: true,
    });

    const oWrapperMainClass = classNames({
      'wrapper-main': true,
      [intl.locale]: true,
      // hasHeroSlider: context.isHeroSlider,
      // [data.site.siteMetadata.title]: true,
      ...oWrapperMainClassNode,
      [className]: className,
    });

    // const ServiceCookieCompliance = loadable(() => import('./s4n/Service/cookieCompliance'));
    // const ServiceTawkTo = loadable(() => import('./s4n/Service/tawkTo'));
    // const ServiceFacebookBadge = loadable(() => import('./s4n/Service/facebookBadge'))
    // const ServiceScrollTop = loadable(() => import('./s4n/Service/scrollTop'));

    // const htmlAttrs = Helmet.htmlAttributes.toComponent();
    // const { htmlAttributes } = Helmet.peek()

    // console.log(Helmet.peek())
    // console.log(Helmet.htmlAttributes)

    // console.log(Helmet.canUseDOM)

    return (



// <ContextScrollableNodesWrapper>



    	<React.Fragment>
        <Helmet
          defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
          
          // @todo: this removes already set classes by 3rd party plugins!!!
          // htmlAttributes={{
          //   class: {
          //     // ...htmlAttrs.class,
          //     // ...htmlAttributes.class,
          //     oClassNamesHtml,
          //   }
          // }}

          bodyAttributes={{
            class: oClassNamesBody,
            // [`data-spy`]: "scroll",
            // [`data-target`]: "#menu-main",
            // [`data-offset`]: "0",
          }}
        >
          {/* Added in src/components/seo.js because when redirection in gatsby-plugin-intl is enabled it won't pick up this meta tag!!! */}
          {/* <meta name="facebook-domain-verification" content="8ivs14x3r7xlheqb1bp3fg5aqarc2a" /> */}
        </Helmet>

        <div 
          // className={cssModule.root}
          className={oWrapperMainClass}
        >

          {/* @see: https://github.com/idiotWu/smooth-scrollbar/tree/65085bf3e75e26a2804323b569c827d548c59900/docs */}
          {/* <Scrollbar
            // damping={number}
            // thumbMinSize={number}
            // syncCallbacks={true}
            renderByPixels={true} // Render every frame in integer pixel values, set to true to improve scrolling performance.
            alwaysShowTracks={false}
            continuousScrolling={true} // Set to true to allow outer scrollbars continue scrolling when current scrollbar reaches edge.
            // wheelEventTarget={element}
            // plugins={object}
            // onScroll={func}

            ref={scrollbarRef}
          > */}
            <a id="top" className={`top`} style={{visibility: `hidden`, opacity: 0, height: 0}}></a>

            <Header 
              
              // Sticky header START
              ref={headerRef}
              className={oHeaderClassNames}
              // Sticky header STOP

              // setHeaderRefCallback={setHeaderRefCallback} // NOT WORKING???!!!
              // forwardedRef={headerRef}
              // scroll={scroll}
              // classNames={`abc`}
              // ref={scrollbarRef}
              // scrollbarRef={scrollbarRef}
              siteTitle={data.site.siteMetadata.title} 
              nodeId={nodeId}
            />

            <main className="main">
              <S4nLayoutBlock name="main" nodeId={nodeId} productId={productId}>
                {children}
              </S4nLayoutBlock>
            </main>

            <Footer />

          {/* </Scrollbar> */}

          {/* Embeed booking.com widget START */}
          {bookingComWidgetTargetElemId && 
            <div id={bookingComWidgetTargetElemId} className={`bookingcom_rwidget`}></div>
            }
          {/* Embeed booking.com widget STOP */}
          
          {false && 
            <React.Fragment>
              {/* <ServiceCookieCompliance /> */}
              {/* <ServiceScrollTop /> */}
              {/* <ServiceTawkTo serviceId="serviceId" /> */}
              {/* <ServiceFacebookBadge serviceId="https://www.facebook.com/soft4net/" serviceName="soft4net.io" /> */}
            </React.Fragment>
          }

        </div>

    	</React.Fragment>



// </ContextScrollableNodesWrapper>



    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// @see: https://gatsbyguides.com/tutorial/authenticate-users-oauth
// @see: https://www.gatsbyjs.org/tutorial/authentication-tutorial/
// Initialize a new drupalOauth client which we can use to seed the context 
// provider.
const drupalOauthClient = new drupalOauth({
  drupal_root: process.env.SOFT4NET_SOURCE_BASE_URL,
  client_id: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_ID,
  client_secret: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_SECRET,
})

const mapStateToProps = state => ({
  fontSize: state.fontSize.fontSize,
  isHighContrastMode: state.highContrast.isHighContrastMode,
})
export default connect(mapStateToProps)(
  withDrupalOauthProvider(drupalOauthClient, 
    Layout
    // React.memo(Layout)
  )
);
// import { Link } from "gatsby"
import React from "react"
import { connect } from 'react-redux'

// s4n
import { useIntl } from "gatsby-plugin-intl"
// import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
// import loadable from '@loadable/component'
// import Preloader from './s4n/Preloader'
import classNames from 'classnames'
import UseDrupalBlockContentBasic from './../hooks/drupal/blockContentBasic'
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"
// import useBuildTime from './../hooks/useBuildTime'
// ES6
//import Webform from 'hn-react-webform';
// import GeneralUtils from './../utils/common'
// import Menu from "./s4n/Menu/menu"
// import MenuUtils from "./s4n/Menu/utils"

// import Logo from './../images/custom/logo.svg'
// import LogoWhite from './../images/custom/logo-white-48.png'
// import { ReactComponent as MapPoland } from './../images/custom/POL_location_map.svg'
// import PromotedFrontPage from './s4n/Promoted/FrontPage'
// import { shared as ConfigShared } from './s4n/Config/Shared'



const Footer = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { hasFooterContactForm } = props;

    // const nodeContact = MenuUtils.getItemByNodeId(5, intl.locale);
    // const buildTime = useBuildTime();
    // const SvgMapPoland = loadable(() => import(`./svg/map-poland`), {fallback: <Preloader />})
    // const FormHeuresisNewsletter = loadable(() => import(`./../components/forms/webform/heuresis_newsletter`), {fallback: <Preloader />})

    const oClassNames = classNames({
        "footer-section": true,
        "hasFooterContactForm": hasFooterContactForm,
        // [`alert-${formStatus.class}`]: true,
    });
    
    return (
        <React.Fragment>
            <footer className={oClassNames}>
                {/* <div className="section"> */}
                    <div className="container">
                        <div className="row flex-column">
                            <div className="col">
                                <UseDrupalBlockContentBasic blockId={1} />
                                {/* <ul className="social">
                                    <li><a className="facebook" href="https://www.facebook.com/pokojewstarejkarczmie/" rel="noreferrer" target="_blank" title="Facebook"><i className="fab fa-facebook">‌‌</i></a></li>
                                    <li><a className="instagram" href="https://www.instagram.com/pokoje_w_starejkarczmie/" rel="noreferrer" target="_blank" title="Instagram"><i className="fab fa-instagram">‌‌</i></a></li>
                                </ul> */}
                            </div>
                            
                            {/* <div className="col">
                                <p>
                                    E-mail: <a data-encoded={GeneralUtils.rot13(t({ id: `soft4net_config_address` }).replace(/ /g, ``))} href="mailto:">{t({ id: `soft4net_config_address` })}</a>
                                </p>
                            </div>
                            <div className="col">
                                <p>
                                    {t({ id: `soft4net_config_phone` })}: <a href={`tel:+48${t({ id: `soft4net_config_phone` })}`}>{t({ id: `soft4net_config_phone` })}</a>
                                </p>
                            </div>
                            <div className="col">
                                <p dangerouslySetInnerHTML={{__html: t({ id: `soft4net_config_address` })}}></p>
                            </div> */}

                            {/* <div className="col">
                                <Link to={33}></Link>
                            </div> */}
                            {/* <div className="col">
                                <div id="copyright">
                                    <p>
                                        Copyright &copy; {new Date().getFullYear()} { t({ id: "soft4net_all_rights_reserved" }) }
                                    </p>
                                    <p>
                                        <a 
                                            href="https://www.soft4net.pl/" 
                                            title={ t({ id: `soft4net_project_realization_anchor_title` }) } 
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            { t({ id: `soft4net_project_realization` }) } 
                                        </a>
                                    </p>
                                    {`true` === process.env.SOFT4NET_DISPLAY_BUILD_TIME_IN_FOOTER && 
                                        <p className="mt-5">
                                            { t({ id: "soft4net_deploy_build_time" }) }: {GeneralUtils.dateFormat(buildTime, "LLL", intl.locale)}
                                        </p>
                                    }
                                </div>
                            </div> */}
                            {/* <div className="col">
                                <Link to={`/`} title={`Soft4Net`}>
                                    <img 
                                        style={{ width: `5rem` }} 
                                        // src={props.isHighContrastMode ? LogoWhite : Logo} 
                                        src={Logo} 
                                        alt={t({ id: `siteMetadata_author` })} className="logo" />
                                </Link>
                            </div> */}
                        </div>
                    </div>
                {/* </div> */}
            </footer>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isHighContrastMode: state.highContrast.isHighContrastMode,
})
export default connect(mapStateToProps)(Footer);
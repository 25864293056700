import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { useIntl } from "gatsby-plugin-intl"
// import Helmet from "react-helmet"
import { toggleHighContrastMode } from './../../../../s4n/state/actions/highContrast'
import $ from 'jquery'
// import useVersion from './../../../../hooks/useVersion'
import classNames from 'classnames';
import { bindOnClickOnKeyDown } from './../index'

export const STYLESHEET_WEBSITE_MAIN_MARKER = `stylesheet-website-main-marker`;
export const STYLESHEET_HIGH_CONTRAST_MARKER = `css-high-contrast-generated`;
export const HIGH_CONTRAST_FILE_PREFIX = `high-contrast-generated_`;

const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const [stylesheetHref, setStylesheetHref] = useState(null);
    // const currentBuildVersion = useVersion();

    const getStylesheetHref = () => {
        if (null !== stylesheetHref) {
            return stylesheetHref;
        }

        let _stylesheetHref = null;
        let $stylesheetWebsiteMain = null;

        if (`true` === process.env.SOFT4NET_STYLESHEET_LINK) {
            $stylesheetWebsiteMain = $(`link#${STYLESHEET_WEBSITE_MAIN_MARKER}`);
        } else {
            $stylesheetWebsiteMain = $(`style#${STYLESHEET_WEBSITE_MAIN_MARKER}`);
        }

        // In development mode styles are not generated to <link> !!!
        if ($stylesheetWebsiteMain.length) {
            if (`true` === process.env.SOFT4NET_STYLESHEET_LINK) { // <link href="/styles.d6044c99c7513e1b7ea9.css" rel="stylesheet" type="text/css"/>
                _stylesheetHref = $stylesheetWebsiteMain.attr(`href`).replace(`/`, ``);
            } else { // <style data-href="/styles.a2b1717ddc1055322295.css"> ... </style>
                _stylesheetHref = $stylesheetWebsiteMain.data(`href`).replace(`/`, ``);
            }
        }

        // Soft4Net creates this in gatsby-ssr.js, but it gets removed, propably by gatsby-plugin-offline!
        if (!$stylesheetWebsiteMain.length) {
            $stylesheetWebsiteMain = $(`meta[name="${STYLESHEET_WEBSITE_MAIN_MARKER}"]`);
            if ($stylesheetWebsiteMain.length) {
                _stylesheetHref = $stylesheetWebsiteMain.attr(`content`).replace(`/`, ``);
            }
        }

        // Propably gatsby-plugin-offline created this after removing style tag! === <link rel="stylesheet" type="text/css" href="/styles.06771411e5450a879a3b.css">
        if (!$stylesheetWebsiteMain.length) {
            $stylesheetWebsiteMain = $(`link[rel="stylesheet"][type="text/css"]`);
            if ($stylesheetWebsiteMain.length) {
                const href = $stylesheetWebsiteMain.attr(`href`);
                if (href.startsWith(`/styles.`) && href.endsWith(`.css`)) {
                    _stylesheetHref = href.replace(`/`, ``);
                }
            }
        }

        return _stylesheetHref;
    }

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = () => {
        setStylesheetHref(getStylesheetHref());
// console.log(`componentDidMount(), stylesheetHref: ` + stylesheetHref);
    }

    const toggleHighContrast = (props) => {
// console.log('toggleHighContrast(), stylesheetHref: ' + stylesheetHref)
        props.toggleHighContrastMode(props.isHighContrastMode); // this modifies redux store state

        if (null === stylesheetHref) {
            setStylesheetHref(getStylesheetHref());
        }

        if (null === stylesheetHref) {
            return false;
        }

        const $head = $(`head`);
        const $stylesheetHighContrast = $head.find(`link#${STYLESHEET_HIGH_CONTRAST_MARKER}`);

// console.log('toggleHighContrast()')
// console.log('stylesheetHref: ' + stylesheetHref)
// console.log('props.isHighContrastMode: ' + props.isHighContrastMode)

        if (props.isHighContrastMode) {
            if ($stylesheetHighContrast.length) {
// console.log('remove()')
                $stylesheetHighContrast.remove();
            }
        } else {
            if (!$stylesheetHighContrast.length) {
// console.log('append()')
                $head.append(`<link href="/${HIGH_CONTRAST_FILE_PREFIX}${stylesheetHref}" rel="stylesheet" type="text/css" id="${STYLESHEET_HIGH_CONTRAST_MARKER}" />`);
                // $head.append(<link href={`/${HIGH_CONTRAST_FILE_PREFIX}${stylesheetHref}`} rel="stylesheet" type="text/css" id={`${STYLESHEET_HIGH_CONTRAST_MARKER}`} />);
            }
        }
    }

    const oClassNames = classNames({
        [`selected`]: props.isHighContrastMode,
    });

    return (
        <React.Fragment>
            {/* This approach from <Helmet /> does not ensure that HighContrast link is added always after main stylesheet */}
            {/* {props.isHighContrastMode && stylesheetHref && 
                <Helmet
                    defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
                >
                    <link href={`/${HIGH_CONTRAST_FILE_PREFIX}${stylesheetHref}`} rel="stylesheet" type="text/css" id={`${STYLESHEET_HIGH_CONTRAST_MARKER}`} />
                </Helmet>
            } */}
            <div className="accessibility-high-contrast">
                <a tabIndex="0" 
                    className={oClassNames}
                    // onClick={() => props.toggleHighContrastMode(props.isHighContrastMode)} 
                    // onKeyDown={() => props.toggleHighContrastMode(props.isHighContrastMode)} 
                    // ===
                    // {...bindOnClickOnKeyDown(() => props.toggleHighContrastMode(props.isHighContrastMode))}
                    {...bindOnClickOnKeyDown(() => toggleHighContrast(props))}
                    title={t({ id: `soft4net_accessibility_high_contrast` })}
                >
                    <span aria-hidden="true"></span>
                </a>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isHighContrastMode: state.highContrast.isHighContrastMode,
})

// const mapDispatchToProps = (dispatch, ownProps) => ({
//     toggleHighContrastMode: () => dispatch(toggleHighContrastMode(!ownProps.isHighContrastMode)),
// })
const mapDispatchToProps = dispatch => ({
    toggleHighContrastMode: (isHighContrastMode) => dispatch(toggleHighContrastMode(!isHighContrastMode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentStateless);